import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import { FaKey, FaCubes, FaCogs } from "react-icons/fa";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";

export const query = graphql`
  query {
    upr1: file(
      relativePath: {
        eq: "Zarzadzanie-uprawnieniami-kierownika-w-prostym-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1347, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    upr2: file(
      relativePath: {
        eq: "Zarzadzanie-uprawnieniami-pracownika-w-prostym-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1347, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Uprawnienia = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Zarządzanie uprawnieniami"
      headline="Zadbaj o bezpieczeństwo informacji"
    >
      <SEO
        title="Zarządzanie uprawnieniami - decyduj, co widzą pracownicy"
        description="Zarządzanie uprawnieniami w CRM • Decyduj o tym, kto ma dostęp do danych • Bezpieczeństwo, komfort, elastyczność • Wypróbuj QuickCRM 30 dni za darmo!"
        keywords={["zarządzanie uprawnieniami"]}
      />
      <div>
        <p>
          <strong>
            W QuickCRM to Ty decydujesz o&nbsp;tym, co widzą Twoi pracownicy.{" "}
          </strong>
          System nie narzuca odgórnie uprawnień dla pracowników. Możesz tworzyć
          profile np. <i>pracownik, </i>które będą miały&nbsp;określony poziom
          uprawnień. W&nbsp;ten sposób decydujesz o&nbsp;dostępie do
          poszczególnych informacji w&nbsp;systemie. Zadbasz także dzięki temu
          o&nbsp;bezpieczeństwo danych, którymi zarządzasz m.in. poprzez
          eliminację ryzyka przypadkowego usunięcia lub edycji danych.
        </p>
        <p>
          <strong>
            Dodatkowo, funkcja zarządzania uprawnieniami pozwala na dostosowanie
            systemu do swoich potrzeb.
          </strong>{" "}
          Co to znaczy? Jeżeli nie korzystasz z&nbsp;danej funkcjonalności,
          możesz ją po prostu ukryć za pomocą uprawnień. Jeśli zajdzie potrzeba,
          w&nbsp;odpowiednim momencie ją włączysz. Zwiększa to komfort
          korzystania z&nbsp;systemu dla wszystkich. Nie ma potrzeby, żeby każdy
          pracownik miał dostęp do zaawansowanych funkcji. Dlaczego miałyby go
          rozpraszać i&nbsp;utrudniać korzystanie z&nbsp;systemu?
        </p>
      </div>
      <br />
      <Lightbox images={[data.upr1, data.upr2]} 
      alts={["zarządzanie uprawnieniami w crm, zarządzanie uprawnieniami w systemie crm"]}/>
      <br />
      <h3
        style={{
          textAlign: "center"
        }}
      >
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaKey size="45px" />}
          content="Pewność, że dane firmy są wyłącznie w odpowiednich rękach"
        />
        <BenefitBlock
          icon={<FaCubes size="45px" />}
          content="Elastyczność w ustalaniu uprawnień"
        />
        <BenefitBlock
          icon={<FaCogs size="45px" />}
          content="Możliwość samodzielnego dostosowania systemu"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default Uprawnienia;
